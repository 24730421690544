import React, { useEffect, useRef, useState } from "react";
import './ResumeHistoryTableItem.scss';
import { faFile, faFilePdf, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import IconButton from "../IconButton/IconButton";
import Icon from "../Icon/Icon";
import ControlModal from "../ControlModal/ControlModal";
import { useNavigate } from 'react-router-dom';

const ResumeHistoryTableItem = ({ resume, onDelete }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const modalRef = useRef(null);
    const navigate = useNavigate();

    const toggleModal = () => setIsModalVisible(prev => !prev);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalVisible(false);
        }
    };

    useEffect(() => {
        if (isModalVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalVisible]);

    const handleEdit = () => navigate(`/template/edit-template-1/${resume.id}`);

    return (
        <section className="resume-history-table-item" id={`resume-${resume.id}`}>
            <div className="resume-history-table-item__left-wrapper">
                <Icon icon={faFile} IconSize="large" color="dark" />
                <div className="resume-history-table-item__left-wrapper-text-container">
                    <h4>{resume.file_name}</h4>
                    <p className="resume-history-table-item__left-wrapper-text-container--text">
                    {new Date(resume.created_at).toLocaleDateString('en-US', { 
                        year: 'numeric', 
                        month: 'long', 
                        day: 'numeric' 
                    })}
                    </p>
                </div>
            </div>

            <div className="resume-history-table-item__right-wrapper">
                <IconButton 
                    isLink={false}
                    icon={faFilePdf}
                    color="grey"
                    buttonSize="large"
                    className="resume-history-table-item__right-wrapper--icon-button"
                />

                <div className="resume-history-table-item__right-wrapper resume-history-table-item__right-wrapper--modal">
                    {isModalVisible && (
                        <ControlModal 
                            ref={modalRef} 
                            resumeId={resume.id}  
                            onEdit={handleEdit} 
                            onDelete={onDelete} 
                        />
                    )}

                    <IconButton 
                        isLink={false}
                        icon={faEllipsisVertical}
                        color="grey"
                        buttonSize="large"
                        onClick={toggleModal}
                        className="resume-history-table-item__right-wrapper--icon-button"
                    />
                </div>
            </div>
        </section>
    );
};

export default ResumeHistoryTableItem;