import React from "react";
import './TextLink.scss';
import { Link } from "react-router-dom";

const TextLink = ({text, to, rel, target}) => {
    return(
        <Link className="text-link" to={to} rel={rel} target={target}>
            {text}
        </Link>
    );
}

export default TextLink;