import React, { useEffect, useState } from "react";
import { faBurger } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Link } from "react-router-dom";
import "./Navbar.scss";
import Button from "../Button/Button";
import Logo from "../Logo/Logo";
import IconButton from "../IconButton/IconButton";
import Icon from "../Icon/Icon";
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

function Navbar({layout}){
    const [drawerControl, setDrawerControl] = useState(false);

    const drawerToggle = () => {
        setDrawerControl(!drawerControl);
    }

    useEffect(()=>{
        if(drawerControl){
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () =>{
            document.body.style.overflow = 'auto';
        };
    }, [drawerControl]);

    switch(layout){
        case "simplified":
            return(
                <header className="navbar--logo-only">
                    <div className="navbar__section-container"> 
                        <Logo />
                    </div>
                </header>
            );
        
        default:
            return(
                <header className="navbar">
                    <div className="navbar__section-container">
                        <div className="navbar__left-panel-wrapper"> 
                            <Logo />
        
                            <div className="navbar__left-panel">
                                <Link className="navbar__link">Product</Link>
                                <Link className="navbar__link">Resource</Link>
                                <Link className="navbar__link">Blog</Link>
                            </div>
        
                            <div className={`navbar__mobile-drawer ${drawerControl ? 'open' : 'close'}`}>
                                <div className="navbar__mobile-drawer-wrapper">
                                    <div className="navbar__mobile-drawer-close-button">
                                        <IconButton 
                                            icon={faXmark} 
                                            className="navbar__link-button" 
                                            onClick={drawerToggle} 
                                            isLink={false}
                                            buttonSize="xlarge"
                                            color="dark"
                                        />
                                    </div>
                                    
                                    <div className="navbar__mobile-drawer-wrapper-upper">
                                        <div className="navbar__mobile-drawer-wrapper-upper--links">
                                            <Link className="navbar__link--dark">Product <Icon icon={faChevronRight} IconSize="default"/></Link>
                                            <Link className="navbar__link--dark">Resource <Icon icon={faChevronRight} IconSize="default"/></Link>
                                            <Link className="navbar__link--dark">Blog <Icon icon={faChevronRight} IconSize="default"/></Link>
                                            <Link className="navbar__link--dark">Product <Icon icon={faChevronRight} IconSize="default"/></Link>
                                            <Link className="navbar__link--dark" to="/login">Login <Icon icon={faChevronRight} IconSize="default"/></Link>
                                        </div>
        
                                        <Button 
                                            isLink={true}
                                            text="Get started"
                                            to="/signup"
                                            buttonSize= "default"
                                        />
                                    </div>
                                </div>
                            </div>
 
                            <IconButton 
                                icon={faBurger} 
                                className="navbar__link-button" 
                                onClick={drawerToggle} 
                                isLink={false}
                                buttonSize="xlarge"
                                color="light"
                            />
                        </div>
        
                        <div className="navbar__right-panel">
                            <Link className="navbar__link" to="/login">Login</Link>
                            <Button 
                                isLink={true}
                                text="Get started"
                                to="/signup"
                                buttonSize= "default"
                            />
                        </div>
                    </div>
                </header>
            );
    }
};

export default Navbar;
