import React from "react";
import { useRef, useState, useEffect } from "react";
import "./LandingPage.scss";
import Navbar from "../../components/Navbar/Navbar";
import PDPHero from "../../components/PDPHero/PDPHero";
import InlineTiles from "../../components/InlineTiles/InlineTiles";
import InlineTilesDB from "../../databases/landingPageInlineTile.json"
import CardTilesDB from "../../databases/landingPageCardTile.json"
import CardTile from "../../components/CardTile/CardTile";
import officeImg from "../../assets/images/office.png";
import promoImage from "../../assets/images/happy-emoji.png";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import SegmentControl from "../../components/SegmentControl/SegmentControl";
import PriceCard from "../../components/PriceCard/PriceCard";

function LandingPage(){
    const [selectedValue1, setSelectedValue1] = useState("monthly");
    const sliderRef = useRef(null);

    const pricing = {
        yearly: {
            essential: "Free",
            advance: "$299",
            expert: "$499"
        },
        monthly: {
            essential: "Free",
            advance: "$29",
            expert: "$49"
        }
    }

    useEffect(() => {
        if (sliderRef.current) {
            const middleCardPosition = sliderRef.current.scrollWidth / 2 - sliderRef.current.clientWidth / 2;
            sliderRef.current.scrollTo({
                left: middleCardPosition,
                behavior: 'smooth'
            });
        }
    }, []);

    return(
        <section>
            <Navbar />

            <main className="landing-page">
                <PDPHero />
                
                <section className="landing-page__tiles-section">
                    <h2>How It Works?</h2>
                    <div className="landing-page__tiles-wrapper">
                        {InlineTilesDB.map((content, index) => (
                        <InlineTiles 
                            key={index}
                            heading={content.heading}
                            body={content.body} 
                            img={content.image}
                            alt={content.alt}
                        />
                        ))}
                    </div>
            </section>

            <section className="landing-page__cards-section">
                    <div className="landing-page__card-text-wrapper">
                        <span className="landing-page__card-text-wrapper--tageline tagline">Features</span>
                        <h2>Unleash Customization Power</h2>
                        <p className="landing-page__card-text-wrapper--text">
                            Add your content effortlessly with our AI-powered tool, ensuring your CV is professional and polished
                        </p>
                    </div>

                    <div className="landing-page__tiles-wrapper landing-page__tiles-wrapper--card">
                        {CardTilesDB.map((content, index) =>(
                            <CardTile 
                                key={index}
                                heading={content.heading}
                                body={content.body} 
                                img={content.image}
                                alt={content.alt}
                            />

                        ))}
                    </div>
            </section>

            <section className="landing-page__pricing-section">
                <div className="landing-page__pricing-heading-wrapper">
                <h2 className="landing-page__pricing-heading-wrapper--title">Find the Ideal Plan for Your Needs</h2>
                    <SegmentControl
                    controlName="group-1"
                    callback={(val) => setSelectedValue1(val)}
                    controlRef={useRef()}
                    options={[
                    {
                        label: "Monthly",
                        value: "monthly",
                        ref: useRef()
                    },
                    {
                        label: "Yearly",
                        value: "yearly",
                        ref: useRef()
                    }
                    ]}
                    />
                </div>

                <div className="landing-page__pricing-section-card-wrapper" ref={sliderRef}>
                    <PriceCard 
                        tageline="Essential"
                        title={pricing[selectedValue1].essential}
                        body="Explore a limited selection of templates and start building your resume without AI assistance."
                        background="grey"
                    />

                    <PriceCard 
                        tageline="Advance"
                        title={pricing[selectedValue1].advance}
                        body="Access 20 professional CV templates and take advantage of AI features to enhance and refine your resume."
                        background="green"
                    />

                    <PriceCard 
                        tageline="Expert"
                        title={pricing[selectedValue1].expert}
                        body="Start your journey today with Redyy. Prepare your resume, streamline your applications, and step confidently into your next opportunity."
                        background="grey"
                    />
                </div>
            </section>

            <section className="landing-page__image-text-section">
                    <div className="landing-page__image-text-left-panel">
                        <h2>Stress-Free Resume Building</h2>
                        <p className="landing-page__image-text-left-panel--text">
                            Redyy simplifies your job application process, offering tools and features that take the hassle out of job searching. Manage applications effortlessly and stay organized with ease.
                        </p>
                    </div>

                    <div className="landing-page__image-text-right-panel">
                        <img className="landing-page__image-text-right-panel--image" src={officeImg} alt="Office scenery"/>
                    </div>
            </section>

            <section className="landing-page__promo-section">
                <div className="landing-page__promo-section-wrapper">
                    <div className="landing-page__promo-left-panel">
                            <div className="landing-page__promo-left-panel-text-wrapper">
                                <h2>Get ready, get hired.</h2>
                                <p className="landing-page__promo-left-panel-text-wrapper--text">
                                    Start your journey today with Redyy. Prepare your resume, streamline your applications, and step confidently into your next opportunity.
                                </p>
                            </div>
                            
                            <Button 
                                isLink={true}
                                text="Sign up today"
                                to="/signup"
                                buttonSize= "default"
                            />
                    </div>

                    <div className="landing-page__promo-right-panel">
                            <img className="landing-page__promo-right-panel--image" src={promoImage} alt="Happy emoji"/>
                    </div>
                </div>
            </section>

            </main>

            < Footer/>
       </section>
    );
};

export default LandingPage;
