import React from "react";
import "./CvTemplateLink.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHighlighterLine } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from "react-router-dom";

const CvTemplateLink = ({templatePreview, alt, isPlaceholder, to}) => {
    return isPlaceholder?(
        <div className="cv-template-placeholder">
            <div className="cv-template-placeholder__container">
                <FontAwesomeIcon 
                    icon={faHighlighterLine} 
                    alt="Highlighter icon"
                    className="cv-template-placeholder__container--icon" 
                />
                <p className="cv-template-placeholder__container--text" >More templates are coming soon</p>
            </div>
        </div>
    ):(
        <Link className="cv-template-link" to={to}>
            <img src={templatePreview} alt={alt} className="cv-template-link--preview"/>
        </Link>
    )
};

export default CvTemplateLink;