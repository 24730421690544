import React, { useState } from "react";
import { Link } from "react-router-dom"; 
import { useNavigate } from 'react-router-dom';
import "./Login.scss";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Button from "../../components/Button/Button";
import RedyyLogo from "../../assets/logo/redyy-logo-small.svg";
import Gif from "../../assets/gif/3d-isometric.gif";
import InputField from "../../components/InputField/InputField";
import axios from "axios";
import Notification from "../../components/Notification/Notification";

function Login() {
    const apiUrl = process.env.REACT_APP_REDYY_API_URL;
    const [email, setEmail] = useState("");  
    const [password, setPassword] = useState("");  
    const [error, setError] = useState(""); 
    const navigate = useNavigate();  


    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiUrl}/api/auth/login`, {
                email,
                password
            });

            if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem('token', token);
                navigate("/dashboard");
            }
        } catch (error) {
            setError("Oops! We couldn’t log you in. Please check your login info!");
        }
    };

    return (
        <section className="login">
            <Navbar layout="simplified"/>

            <main className="login__main-container">
                <div className="login__left-panel">
                    <div className="login__text-wrapper">
                        <img className="login__redyy-logo" src={RedyyLogo} alt="Redyy logo"/>
                        <span className="login__text-wrapper--tagline tagline">Start your resume</span>
                        <h2 className="login__text-wrapper--title">Welcome back to Redyy</h2>
                    </div>

                    {error && <Notification text={error} layout="error" />}

                    <form className="login__field-wrapper" onSubmit={handleLogin}>
                        <div className="login__field-wrapper--form">
                            <InputField
                                label="Email"
                                type="text"
                                placeholder=""
                                requirement="require"
                                value={email}  
                                onChange={(e) => setEmail(e.target.value)}  
                                isRequire={true}
                            />
                            
                            <InputField
                                label="Password"
                                type="password"  
                                placeholder=""
                                requirement="require"
                                value={password}  
                                onChange={(e) => setPassword(e.target.value)}  
                                isRequire={true}
                            />
                        </div>

                        <Button
                            text="Login"
                            buttonWidth="full-width"
                            buttonSize="default"
                        />
                    </form>

                    <p className="login__login-text">Don’t have an account yet?&#160;
                        <span>
                            <Link to="/signup" className="login__login-text--link">Get started and create one today!</Link>
                        </span>
                    </p>
                </div>

                <div className="login__right-panel">
                    <img className="login__right-panel--gif" src={Gif} alt="3D isometric animation"/>
                </div>
            </main>

            <Footer layout="simplified-green"/>
        </section>
    );
}

export default Login;