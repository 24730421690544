import React from "react";
import "./Icon.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Icon({ icon, color, IconSize, className }){
    return(
        <div className={`icon-container icon-container--container-${IconSize}`}>
            <FontAwesomeIcon icon={icon} className={`icon-container--icon icon-container--${IconSize} icon-container--${color} ${className}`}/>
        </div>
    );
};

export default Icon;