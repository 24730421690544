import React, { useState } from "react";
import axios from "axios";
import AiButton from "../AiButton/AiButton";
import "./Textarea.scss";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

const Textarea = ({ label, placeholder, onChange, value, isAiButton, name }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const apiKey = process.env.REACT_APP_OPEN_AI_API_KEY;

  const handleBlur = () => {
    if (!value || value.trim() === "") {
      setError("This field is required");
    } else {
      setError("");
    }
  };

  const handleAiRephrase = async (e) => {
    e.preventDefault();
    if (!value) return;

    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo", 
          messages: [
            {
              role: "system",
              content:
                "You are a professional assistant that helps improve text by fixing grammar, making it smoother and professional, while keeping the word count similar.",
            },
            {
              role: "user",
              content: `Rephrase the following text: "${value}"`,
            },
          ],
          max_tokens: 1000, // I guess is text amount generate? 
          temperature: 0.7, // control the creativity
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const rephrasedText = response.data.choices[0].message.content.trim();
      onChange({ target: { name, value: rephrasedText } });
    } catch (error) {
      setError("An error occurred while rephrasing. Please try again later.");
    }
  };

  return isAiButton ? (
    <div className="textarea">
      <div className="textarea__label-wrapper">
        <label className="textarea__label">{label}</label>
        <AiButton onClick={handleAiRephrase} />
      </div>

      <textarea
        className="textarea__input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows="4"
        cols="50"
        name={name}
        id={name}
      />

      {error && 
        <div className="textarea__error">
          <FontAwesomeIcon icon={faCircleXmark} />
          <span className="textarea__error--text">{error}</span>
        </div>
      }
    </div>
  ) : (
    <div className="textarea">
      <label className="textarea__label">{label}</label>
      <textarea
        className={`textarea__input ${error ? `textarea__input--error` : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows="4"
        cols="50"
        name={name}
        id={name}
        onBlur={handleBlur}
      />
        {error && 
          <div className="textarea__error">
            <FontAwesomeIcon icon={faCircleXmark} />
            <span className="textarea__error--text">{error}</span>
          </div>
        }
    </div>
  );
};

export default Textarea;