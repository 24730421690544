import './App.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import Template from './pages/Template/Template';
import AddTemplateOne from './pages/AddTemplateOne/AddTemplateOne';
import EditTemplateOne from './pages/EditTemplateOne/EditTemplateOne';
import ApplicationTracker from './pages/ApplicationTracker/ApplicationTracker';
import Support from './pages/Support/Support';
import Settings from './pages/Settings/Settings';

function App() {
  return (
    <div className="app">
      <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/template" element={<Template />} />
            <Route path="/template/add-template-1" element={<AddTemplateOne />} />
            <Route path="/template/edit-template-1/:id" element={<EditTemplateOne />} />
            <Route path="/application-tracker" element={<ApplicationTracker />}/>
            <Route path="/settings" element={<Settings />}/>
            <Route path="/support" element={<Support />}/>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
