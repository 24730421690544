import React, { useState } from "react";
import "./InputField.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

const InputField = ({label, type, placeholder, onChange, value, isRequire, name}) =>{
    const [error, setError] = useState("");

    const handleBlur = () => {
        if (!value || value.trim() === "") {
            setError("This field is required");
        } else {
            setError("");
        }
    };

    return isRequire?(
        <div className="input-field">
            <label className="input-field__label">{label}</label>
            <input 
                className={`input-field__input ${error ? `input-field__input--error` : ''}`}
                type={type} 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                name={name}
            />

            {error && 
                <div className="input-field__error">
                    <FontAwesomeIcon icon={faCircleXmark} />
                    <span className="input-field__error--text">{error}</span>
                </div>
            }
        </div>
    ):(
        <div className="input-field">
            <label className="input-field__label">{label}</label>
            <input 
                className="input-field__input"
                type={type} 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
            />
        </div>
    )
}

export default InputField;