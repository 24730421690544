import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./SideNavbar.scss";
import SideNavbarLink from "../SideNavbarLink/SideNavbarLink";
import { faXmark, faHouse, faFile, faClipboardListCheck, faGear, faMessageSmile, faBurger } from '@fortawesome/pro-regular-svg-icons';
import Logo from "../Logo/Logo";
import IconButton from "../IconButton/IconButton";

function SideNavbar(){
    const [drawerControl, setDrawerControl] = useState(false);
    const location = useLocation(); 

    const drawerToggle = () => {
        setDrawerControl(!drawerControl);
    }

    useEffect(() =>{
        if(drawerControl){
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () =>{
            document.body.style.overflow = 'auto';
        }
    }, [drawerControl]);

    return(
        <header className="side-navbar">
            <div className="side-navbar__upper-panel">
                <div className="side-navbar__upper-panel--logo">
                    <Logo isDashboard={true}/>
                </div>

                <IconButton 
                    icon={faBurger} 
                    className="side-navbar__hamburger" 
                    onClick={drawerToggle} 
                    isLink={false}
                    buttonSize="xlarge"
                    color="light"
                />

                <div className={`side-navbar__mobile-drawer ${drawerControl ? 'open' : 'close'}`}>
                    <div className="side-navbar__mobile-drawer-wrapper">
                        <div className="side-navbar__mobile-drawer-close-button">
                            <IconButton 
                                icon={faXmark} 
                                className="side-navbar__link-button" 
                                onClick={drawerToggle} 
                                isLink={false}
                                buttonSize="xlarge"
                                color="dark"
                            />
                        </div>
                                    
                        <div className="side-navbar__mobile-drawer-container">
                            <div className="side-navbar__mobile-drawer-container-wrapper">
                                <div className="side-navbar__mobile-drawer-container-wrapper--upper">
                                    <SideNavbarLink 
                                        text="Dashboard"
                                        icon={faHouse}
                                        to="/dashboard"
                                        isActive={location.pathname === "/dashboard"}
                                    />

                                    <SideNavbarLink 
                                        text="Templates"
                                        icon={faFile}
                                        to="/template"
                                        isActive={location.pathname === "/template"}
                                    />

                                    <SideNavbarLink 
                                        text="Application tracker"
                                        icon={faClipboardListCheck}
                                        to="/application-tracker"
                                        isActive={location.pathname === "/application-tracker"}
                                    />
                                </div>

                                <div className="side-navbar__mobile-drawer-container-wrapper--lower">
                                    <SideNavbarLink 
                                        text="Support"
                                        icon={faMessageSmile}
                                        to="/support"
                                        isActive={location.pathname === "/support"}
                                    />

                                    <SideNavbarLink 
                                        text="Settings"
                                        icon={faGear}
                                        to="/settings"
                                        isActive={location.pathname === "/settings"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="side-navbar__upper-panel--link-wrapper">
                    <SideNavbarLink 
                        text="Dashboard"
                        icon={faHouse}
                        to="/dashboard"
                        isActive={location.pathname === "/dashboard"}
                    />

                    <SideNavbarLink 
                        text="Templates"
                        icon={faFile}
                        to="/template"
                        isActive={location.pathname === "/template"}
                    />

                    <SideNavbarLink 
                        text="Application tracker"
                        icon={faClipboardListCheck}
                        to="/application-tracker"
                        isActive={location.pathname === "/application-tracker"}
                    />
                </div>
            </div>

            <div className="side-navbar__lower-panel">
                    <SideNavbarLink 
                        text="Support"
                        icon={faMessageSmile}
                        to="/support"
                        isActive={location.pathname === "/support"}
                    />

                    <SideNavbarLink 
                        text="Settings"
                        icon={faGear}
                        to="/settings"
                        isActive={location.pathname === "/settings"}
                    />
            </div>
        </header>
    );
};

export default SideNavbar;
