import React, { forwardRef } from 'react';
import './ControlModal.scss';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../Icon/Icon';

const ControlModal = forwardRef(({ resumeId, onEdit, onDelete }, ref) => {
    return (
        <div className='control-modal' ref={ref}>
            <button onClick={() => onEdit(resumeId)} className='control-modal__button control-modal__button--edit'>
                <Icon IconSize="default" icon={faPen} className='control-modal__icon--edit'/>
                <span className='control-modal__text control-modal__text--edit'>Edit</span>
            </button>

            <button onClick={() => onDelete(resumeId)} className='control-modal__button control-modal__button--delete'>
                <Icon IconSize="default" icon={faTrash} className='control-modal__icon--delete'/>
                <span className='control-modal__text control-modal__text--delete'>Delete</span>
            </button>
        </div>
    );
});

export default ControlModal;