import React from "react";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const ApplicationTracker = () =>{
    return(
        <section>
            <ComingSoon title="Application tracker"/>
        </section>
    );
}

export default ApplicationTracker;