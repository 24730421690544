import React, { useEffect, useState } from "react";
import './LastestPostTable.scss';
import LastestPostTableItem from "../LastestPostTableItem/LastestPostTableItem";

const fetchJobs = async (page) => {
    const url = `https://www.themuse.com/api/public/jobs?page=1&category=Design&job_title=Product%20Designer`;
    const response = await fetch(url);
    const data = await response.json();
    return data.results || [];
};

const LastestPostTable = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchMultiplePages = async () => {
            const allJobs = [];

            for (let page = 1; page <= 5; page++) {
                const pageJobs = await fetchJobs(page);
                allJobs.push(...pageJobs);
                if (allJobs.length >= 5) {
                    break;
                }
            }

            setJobs(allJobs.slice(0, 5));
        };

        fetchMultiplePages();
    }, []);

    return (
        <section className="trending-post-table">
            {jobs.length > 0 ? (
                jobs.map((job, index) => (
                    <LastestPostTableItem
                        key={index}
                        jobTitle={job.name}
                        companyName={job.company?.name}
                        to={job.refs.landing_page}
                        logo={job.company?.refs?.logo_image}
                    />
                ))
            ) : (
                <p className="loader">Loading jobs...</p>
            )}
        </section>
    );
}

export default LastestPostTable;