import React from "react";
import { Link } from "react-router-dom";
import './Button.scss'

const Button = ({type, isLink, text, buttonSize, buttonWidth, to, onClick, target, rel}) =>{
    switch(type){
        case "secondary":
            return isLink?(
                <Link className={`button button--secondary button__${buttonSize} button__${buttonWidth}`} target={target} rel={rel} to={to}>
                    <p className={`button__text button__${buttonWidth}`}>{text}</p>
                </Link>
            ):(
                <button className={`button button--secondary button__${buttonSize} button__${buttonWidth}`} onClick={onClick}>
                    <p className={`button__text button__${buttonWidth}`}>{text}</p>
                </button>
            );
        
        case "ghost":
            return isLink?(
                <Link className={`button button--ghost button__${buttonSize} button__${buttonWidth}`} target={target} rel={rel} to={to}>
                    <p className={`button__text button__${buttonWidth}`}>{text}</p>
                </Link>
            ):(
                <button className={`button button--ghost button__${buttonSize} button__${buttonWidth}`} onClick={onClick}>
                    <p className={`button__text button__${buttonWidth}`}>{text}</p>
                </button>
            );
        
        default:
            return isLink?(
                <Link className={`button button--primary button__${buttonSize} button__${buttonWidth}`} target={target} rel={rel} to={to}>
                    <p className={`button__text button__${buttonWidth}`}>{text}</p>
                </Link>
            ):(
                <button className={`button button--primary button__${buttonSize} button__${buttonWidth}`} onClick={onClick}>
                    <p className={`button__text button__${buttonWidth}`}>{text}</p>
                </button>
            );
    }
};

export default Button;