import { useRef, useState, useEffect } from "react";
import "./SegmentControl.scss";

const SegmentControl = ({controlName, options = [], callback, defaultIndex = 0, controlRef}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef();

  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    if (options.length > 0 && options[activeIndex]) {   
      const activeSegmentRef = options[activeIndex].ref;
      const { offsetWidth, offsetLeft } = activeSegmentRef.current;
      const { style } = controlRef.current;

      style.setProperty("--highlight-width", `${offsetWidth}px`);
      style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }
  }, [activeIndex, callback, controlRef, options]);

  const onInputChange = (value, index) => {
    setActiveIndex(index);
    if (callback) {
      callback(value, index);
    }
  };

  return (
    <div className="segment-control" ref={controlRef}>
      <div className={`segment-control__controls ${componentReady.current ? "segment-control__controls--ready" : "idle"}`}>
        {options?.map((item, i) => (
          <div
            key={item.value}
            className={`segment-control__segment ${i === activeIndex ? "segment-control__segment--active" : "inactive"}`}
            ref={item.ref}
          >
            <input
              type="radio"
              className="segment-control__input"
              value={item.value}
              id={item.label}
              name={controlName}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label className="segment-control__label" htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentControl;