import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const IFrame = ({ children, width, height, scale }) => {
  const [ref, setRef] = useState(null);
  const container = ref && ref.contentWindow && ref.contentWindow.document && ref.contentWindow.document.body;

  useEffect(() => {
    if (ref) {
      const iframeDoc = ref.contentDocument || ref.contentWindow.document;
      iframeDoc.head.innerHTML = '';

      // this is giving me hard time LOL didn't know you need to append inline style like this
      const links = document.querySelectorAll('link[rel="stylesheet"]');
      links.forEach(link => {
        const newLink = iframeDoc.createElement('link');
        newLink.rel = 'stylesheet';
        newLink.href = link.href;
        iframeDoc.head.appendChild(newLink);
      });

      const styles = document.querySelectorAll('style');
      styles.forEach(style => {
        const newStyle = iframeDoc.createElement('style');
        newStyle.textContent = style.textContent;
        iframeDoc.head.appendChild(newStyle);
      });

      if (container) {
        container.style.transform = `scale(${scale})`;   
        container.style.transformOrigin = 'center top';   
        container.style.overflow = 'hidden';       
        container.style.display = 'flex';                
        container.style.justifyContent = 'center'; 
      }
    }
  }, [ref, scale]);

  return (
    <iframe ref={setRef} style={{ width, height, border: 'none', overflow: 'hidden' }}>
      {container && createPortal(children, container)}
    </iframe>
  );
};

export default IFrame;