import React, { useEffect, useState } from "react";
import './ResumeHistoryTable.scss';
import ResumeHistoryTableItem from "../ResumeHistoryTableItem/ResumeHistoryTableItem";
import axios from 'axios'; 
import { useNavigate } from "react-router";

const ResumeHistoryTable = () => {
    const apiUrl = process.env.REACT_APP_REDYY_API_URL;
    const [resumes, setResumes] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchResumes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/resumes`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setResumes(response.data);
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    throw(error);
                }
            }
        };

        if (token) {
            fetchResumes();
        }
    }, [token, navigate]);

    const handleDelete = async (resumeId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        try {
            await axios.delete(`${apiUrl}/api/resumes/${resumeId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setResumes(prevResumes => prevResumes.filter(resume => resume.id !== resumeId));

        } catch (error) {
            throw(error);
        }
    };

    return (
        <section className="resume-history-table">
            {resumes.length === 0 ? (
                <div className="resume-history-table__no-resumes">
                    <p className="resume-history-table__no-resumes--text">You don’t have any resumes yet.</p>
                </div>
            ) : (
                resumes.map(resume => (
                    <ResumeHistoryTableItem key={resume.id} resume={resume} onDelete={handleDelete} />
                ))
            )}
        </section>
    );
};

export default ResumeHistoryTable;