import React from "react";
import './CvTemplate.scss';
import { faMobile, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import Icon from "../Icon/Icon";

const CvTemplate = ({ name, id, jobTitle, phoneNumber, email, education, aboutMe, experiences, skills, certifications, className }) => {
    return (
        <section className={`cv-template ${className}`} id={id}>
            <div className="cv-template__intro-container">
                <div className="cv-template__name-section">
                    <p className="cv-template__name-section--name">{name || 'Your Name'}</p>
                    <span className="cv-template__name-section--job">{jobTitle || 'Job Title'}</span>
                </div>

                <div className="cv-template__contact-section">
                    <div className="cv-template__contact-container">
                        <Icon IconSize="default" icon={faMobile} />
                        <span>{phoneNumber || 'Phone number'}</span>
                    </div>
                    <div className="cv-template__contact-container">
                        <Icon IconSize="default" icon={faEnvelope} />
                        <span>{email || 'Email here'}</span>
                    </div>
                </div>
            </div>

            <div className="cv-template__history-container">
                <div className="cv-template__education-section">
                    <div className="cv-template__education-section--title cv-template__title">Education</div>
                    <p className="cv-template__education-section--text cv-template__body">
                        {education || 'Lorem ipsum dolor sit amet consectetur.'}
                    </p>
                </div>

                <div className="cv-template__about-section">
                    <div className="cv-template__about-section--title cv-template__title">
                        About me
                    </div>
                    <p className="cv-template__about-section--text cv-template__body">
                        {aboutMe || 'Lorem ipsum dolor sit amet consectetur.'}
                    </p>
                </div>
            </div>

            <div className="cv-template__experience-container">
                <div className="cv-template__experience-container--title cv-template__title">Experience</div>
                {experiences.length > 0 ? (
                    experiences.map((exp, index) => (
                        <div key={index} className="cv-template__experience-section cv-template__body">
                            <div className="cv-template__company-wrapper">
                                <p className="cv-template__company-wrapper--company">{exp.company}</p>
                                <p className="cv-template__company-wrapper--timeline">{exp.timeline}</p>
                            </div>
                            <p>{exp.title}</p>
                            <p>{exp.description}</p>
                        </div>
                    ))
                ) : (
                    <p className="cv-template__placeholder">No experience added.</p>
                )}
            </div>

            <div className="cv-template__skill-container">
                <div className="cv-template__skill-container--title cv-template__title">Skills</div>
                <div className="cv-template__skill-section cv-template__body">
                    {skills.length > 0 ? (
                        skills.reduce((resultArray, skill, index) => {
                            const gropIndex = Math.floor(index / 4);

                            if (!resultArray[gropIndex]) {
                                resultArray[gropIndex] = [];  
                            }

                            resultArray[gropIndex].push(skill);

                            return resultArray;
                        }, []).map((chunk, gropIndex) => (
                            <div key={gropIndex} className="cv-template__skill-section-wrapper">
                                {chunk.map((skill, index) => (
                                    <p key={index} className="cv-template__skill-section-wrapper--title cv-template__body">{skill}</p>
                                ))}
                            </div>
                        ))
                    ) : (
                        <p className="cv-template__placeholder">No skills added.</p>
                    )}
                </div>
            </div>

            <div className="cv-template__certification-container">
                <div className="cv-template__certification-container--title cv-template__title">Certification</div>
                {certifications.length > 0 ? (
                    certifications.map((cert, index) => (
                        <div key={index} className="cv-template__certification-section-wrapper">
                            <p className="cv-template__certification-section-wrapper--title cv-template__body">{cert.name}</p>
                            <p className="cv-template__certification-section-wrapper--info cv-template__body">{cert.about}</p>
                        </div>
                    ))
                ) : (
                    <p className="cv-template__placeholder">No certifications added.</p>
                )}
            </div>

        </section>
    );
};

export default CvTemplate;