import React from "react";
import './CompanyTableItem.scss';
import TextLink from "../TextLink/TextLink";

const CompanyTableItem = ({companyName, logo, to}) => {
    return(
        <section className="company-table-item">
            <div className="company-table-item__text-container">
                {logo? (
                    <div className="company-table-item__image-container">
                        <img src={logo} className="company-table-item__image-container--logo" alt="Company's logo"/>
                    </div>
                    
                ):(
                    <div className="company-table-item__text-container--placeholder"></div>
                )}
                <h4>{companyName}</h4>
            </div>

            <TextLink 
                text="Go to site" 
                to={to}
                target="_blank" 
                rel="noopener noreferrer"
            />
        </section>
    );
}

export default CompanyTableItem;