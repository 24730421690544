import React from "react";
import "./CvTemplateLinkGroup.scss"
import CvTemplateLink from "../CvTemplateLink/CvTemplateLink";
import CvPreviewList from "../../databases/cvLinkGroup.json";

const CvTemplateLinkGroup = () => {
    return(
        <div className="cv-template-link-group">

            {CvPreviewList.map((cv, index) => (
                <CvTemplateLink 
                    key={index}
                    templatePreview={cv.image}
                    isPlaceholder={false}
                    alt={cv.alt}
                    to={cv.link}
                />
            ))}
            <CvTemplateLink isPlaceholder={true}/>
        </div>
    );
};

export default CvTemplateLinkGroup; 