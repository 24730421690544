import "./PriceCard.scss";
import Button from "../Button/Button";

const PriceCard = ({tageline, title, body, background}) =>{
    return(
        <div className={`price-card ${background}`}>
            <div className="price-card__text-wrapper">
                <span className="price-card__tageline">{tageline}</span>
                <div className="price-card__title-wrapper">
                    <h3 className="price-card__title-wrapper--title">{title}</h3>
                    <span className="price-card__title-wrapper--subtitle">per seat / mo</span>
                </div>
                <p className="price-card__title-wrapper--text">{body}</p>
            </div>

            <Button 
                isLink={true} 
                text ="Sign up today" 
                buttonSize= "default"
                to="/signup"
            />
        </div>
    );
}

export default PriceCard;