import React from "react";
import "./Dashboard.scss";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import TableTitle from "../../components/TableTitle/TableTitle";
import { faFile, faBook, faBullhorn } from '@fortawesome/pro-duotone-svg-icons';
import ResumeHistoryTable from "../../components/ResumeHistoryTable/ResumeHistoryTable";
import CompanyTable from "../../components/CompanyTable/CompanyTable";
import LastestPostTable from "../../components/LastestPostTable/LastestPostTable";
import Footer from "../../components/Footer/Footer";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import RedyySimplifiedLogo from '../../assets/logo/redyy-logo-small.svg'

function Dashboard() {
    const apiUrl = process.env.REACT_APP_REDYY_API_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const navigate = useNavigate();   
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchProfile = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');   
                return;
            }

            try {
                const response = await axios.get(`${apiUrl}/api/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,   
                    },
                });

                const { firstName, email } = response.data;
                setUserInfo({ firstName, email });
                localStorage.setItem('first_name', firstName);  
                setIsLoading(false);

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');   
                    navigate('/login');
                }
            }
        };

        fetchProfile();
    }, [navigate]);
    
    const username = localStorage.getItem('first_name') || 'User';  

    if (isLoading) {
        return <div className="loading"><img src={RedyySimplifiedLogo} alt="Redyy small logo"/><h1>Loading...</h1></div>;
    }

    return (
        <section className="dashboard">
            <SideNavbar />

            <main className="dashboard__content-container">
                <DashboardHeader layout="main" username={username} />

                <div className="dashboard__list-container">
                    <div className="dashboard__resume-history-section">
                        <TableTitle icon={faFile} title="Resume history" />
                        <ResumeHistoryTable />
                    </div>

                    <div className="dashboard__list-container-double">
                        <div className="dashboard__resume-history-section">
                            <TableTitle icon={faBook} title="Company directory" />
                            <CompanyTable />
                        </div>

                        <div className="dashboard__resume-history-section">
                            <TableTitle icon={faBullhorn} title="Latest job post" />
                            <LastestPostTable />
                        </div>
                    </div>
                </div>
                <Footer layout="simplified-grey" />
            </main>
        </section>
    );
}

export default Dashboard;