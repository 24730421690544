import React from "react";
import './LastestPostTableItem.scss';
import Button from "../Button/Button";

const LastestPostTableItem = ({jobTitle, companyName, to, logo}) => {
    return(
        <section className="trending-post-table-item">
            <div className="trending-post-table-item__left-panel">
                {logo? (
                    <img src={logo} className="trending-post-table-item__left-panel--logo" alt="Company's logo"/>
                ) : (
                    <div className="trending-post-table-item__left-panel--placeholder">{companyName[0]}</div>
                )}

                <div className="trending-post-table-item__text-container">
                    <h4>{jobTitle}</h4>
                    <p className="trending-post-table-item__text-container--text">{companyName}</p>
                </div>
            </div>

            <Button 
                isLink={true}
                buttonSize="xsmall"
                to={to}
                text="Apply"
                target="_blank" 
                rel="noopener noreferrer"
            />
        </section>
    );
}

export default LastestPostTableItem;