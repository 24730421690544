import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import RedyyLogo from "../../assets/logo/redyy-logo.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const Footer = ({layout, isFixed}) =>{
    const [openIndex, setOpenIndex] = useState(null);

    const accordionToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    }

    switch(layout){
        case "simplified-green":
            return(
                <footer className="footer footer--simplified-green">
                   <span className="footer__legal footer__legal--simplified">© 2024 Redyy. All rights reserved.</span>
                </footer>
            );

        case "simplified-grey":
            return(
                <footer className={`footer footer--simplified-grey ${isFixed ? 'footer--fixed' : ''}`}>
                   <span className="footer__legal footer__legal--simplified-grey">© 2024 Redyy. All rights reserved.</span>
                </footer>
            );
        
        default:
            return(
                <footer className="footer">
                    <div className="footer__section-container">
                        <div className="footer__top-panel">
                            <Link to="/" className="footer__top-panel--logo"><img className="footer__logo" src={RedyyLogo} alt="Redyy logo"/></Link>
                            <div className="footer__top-panel--links-wrapper">
                                <Link className="footer__link"><FontAwesomeIcon icon={faInstagram} className="footer__link--icon"/></Link>
                                <Link className="footer__link"><FontAwesomeIcon icon={faSquareFacebook} className="footer__link--icon"/></Link>
                                <Link className="footer__link" to="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} className="footer__link--icon"/></Link>
                            </div>
                        </div>
        
                        <div className="footer__middle-panel footer__middle-panel--desktop">
                            <div className="footer__middle-panel--section">
                                <span className="footer__middle-panel--title">About Us</span>
                                <Link className="footer__link">About Redyy</Link>
                                <Link className="footer__link">Career</Link>
                            </div>
        
                            <div className="footer__middle-panel--section">
                                <span className="footer__middle-panel--title">Product</span>
                                <Link className="footer__link">Dashboard</Link>
                                <Link className="footer__link">Signup </Link>
                            </div>
        
                            <div className="footer__middle-panel--section">
                                <span className="footer__middle-panel--title">Learn More</span>
                                <Link className="footer__link">Contact us</Link>
                                <Link className="footer__link">Blog</Link>
                            </div>
        
                            <div className="footer__middle-panel--section">
                                <span className="footer__middle-panel--title">Support</span>
                                <Link className="footer__link">Support center</Link>
                            </div>
                        </div>
        
                        <div className="footer__middle-panel--mobile">
                            <div className="footer__mobile-accordion-wrapper" onClick={()=>accordionToggle(0)}>
                                <div className="footer__mobile-accordion-wrapper--accordion">
                                    <span className="footer__middle-panel--title">About Us</span>
                                    <FontAwesomeIcon icon={faCaretDown} className={`footer__mobile-accordion-wrapper--accordion-animation ${openIndex === 0 ? 'footer__mobile-accordion-wrapper--accordion-rotate' : ''}`} />
                                </div>
        
                                {openIndex === 0 && <div className="footer__mobile-accordion-wrapper--accordion-content">
                                    <Link className="footer__link">About Redyy</Link>
                                    <Link className="footer__link">Career</Link>
                                </div>}
                            </div>
        
                            <div className="footer__mobile-accordion-wrapper" onClick={()=>accordionToggle(1)}>
                                <div className="footer__mobile-accordion-wrapper--accordion">
                                    <span className="footer__middle-panel--title">Product</span>
                                    <FontAwesomeIcon icon={faCaretDown} className={`footer__mobile-accordion-wrapper--accordion-animation ${openIndex === 1 ? 'footer__mobile-accordion-wrapper--accordion-rotate' : ''}`} />
                                </div>
        
                                {openIndex === 1 && <div className="footer__mobile-accordion-wrapper--accordion-content">
                                    <Link className="footer__link">Dashboard</Link>
                                    <Link className="footer__link">Signup</Link>
                                </div>}
                            </div>
        
                            <div className="footer__mobile-accordion-wrapper" onClick={()=>accordionToggle(2)}>
                                <div className="footer__mobile-accordion-wrapper--accordion">
                                    <span className="footer__middle-panel--title">Learn More</span>
                                    <FontAwesomeIcon icon={faCaretDown} className={`footer__mobile-accordion-wrapper--accordion-animation ${openIndex === 2 ? 'footer__mobile-accordion-wrapper--accordion-rotate' : ''}`} />
                                </div>
        
                                {openIndex === 2 && <div className="footer__mobile-accordion-wrapper--accordion-content">
                                    <Link className="footer__link">Contact us</Link>
                                    <Link className="footer__link">Blog</Link>
                                </div>}
                            </div>
        
                            <div className="footer__mobile-accordion-wrapper" onClick={()=>accordionToggle(3)}>
                                <div className="footer__mobile-accordion-wrapper--accordion">
                                    <span className="footer__middle-panel--title">Support</span>
                                    <FontAwesomeIcon icon={faCaretDown} className={`footer__mobile-accordion-wrapper--accordion-animation ${openIndex === 3 ? 'footer__mobile-accordion-wrapper--accordion-rotate' : ''}`} />
                                </div>
        
                                {openIndex === 3 && <div className="footer__mobile-accordion-wrapper--accordion-content">
                                    <Link className="footer__link">Support center</Link>
                                </div>}
                            </div>
                        </div>
        
                        <span className="footer__legal footer__legal--default">© 2024 Redyy. All rights reserved.</span>
                    </div>
                </footer>
            );
    }
}

export default Footer;