import React from "react";
import './InlineTiles.scss'

const InlineTiles = ({heading, body, img, alt}) =>{
    return(
        <section className="inline-tile">
            <img className="inline-tile__image" src={img} alt={alt}/>
            <h3>{heading}</h3>
            <p className="inline-tile__text">{body}</p>
        </section>
    );
};

export default InlineTiles;