import React from "react";
import "./SideNavbarLink.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from "react-router-dom";

function SideNavbarLink({icon, text, to}){
    return(
        <NavLink to={to}  className={({ isActive }) =>  isActive ? "side-navber-link active" : "side-navber-link"}>
            <div className="side-navber-link__icon-container">
                <FontAwesomeIcon 
                    icon={icon} 
                    className="side-navber-link__icon-container--icon"
                />
            </div>

            <span className="side-navber-link--text">{text}</span>
        </NavLink>
    );
};

export default SideNavbarLink;
