import React from "react";
import { useEffect } from "react";
import Button from "../Button/Button";
import mockupImage from "../../assets/images/redyy-desktop-image.png"
import mockupImage2 from "../../assets/images/redyy-desktop-windows.png";
import "./PDPHero.scss"

const PDPHero = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            const image = document.querySelector('.pdp-hero__lower-panel--image-parallel');
            const maxScrollDistance = 50;
            const scrollDistance = Math.min(scrollPosition * 0.1, maxScrollDistance);
    
            if (image) {
                image.style.transform = `translate(-50%, ${scrollDistance}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <section>
            <div className="pdp-hero">
                <div className="pdp-hero__section-container">
                    <div className="pdp-hero__upper-panel">
                        <div className="pdp-hero__upper-panel-text">
                            <h1>Elevate Your Resume Game</h1>
                            <p className="pdp-hero__upper-panel-text--text">
                                Effortlessly build your standout resume with our easy-to-use templates. Highlight your strengths and achievements to make a lasting impression. Start now and unlock new opportunities!
                            </p>
                        </div>
                    <Button
                        text="Sign up now"
                        isLink={true}
                        to="/signup"
                        buttonSize= "default"
                     />
                    </div>

                    <div className="pdp-hero__lower-panel">
                        <img className="pdp-hero__lower-panel--image-parallel" src={mockupImage2} alt="Product overview"/>
                        <img className="pdp-hero__lower-panel--image" src={mockupImage} alt="Product overview"/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default PDPHero;