import React from "react";
import "./Template.scss";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import TableTitle from "../../components/TableTitle/TableTitle";
import { faFile } from '@fortawesome/pro-duotone-svg-icons';
import ResumeHistoryTable from "../../components/ResumeHistoryTable/ResumeHistoryTable";
import Footer from "../../components/Footer/Footer";
import CvTemplateLinkGroup from "../../components/CvTemplateLinkGroup/CvTemplateLinkGroup";

function Template(){
    return(
        <section className="template">
            <SideNavbar />

            <main className="template__content-container">
                <DashboardHeader title="Template"/>

                <div className="template__list-container">
                    <div className="template__content-section">
                        <TableTitle icon={faFile} title="Choose a template"/>
                        <CvTemplateLinkGroup />
                    </div>

                    <div className="template__content-section">
                        <TableTitle icon={faFile} title="Resume history"/>
                        <ResumeHistoryTable />
                    </div>
                </div>

                <Footer layout="simplified-grey"/>
            </main>
        </section>
    );
};

export default Template;
