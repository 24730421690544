import React from "react";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const Support = () =>{
    return(
        <section>
            <ComingSoon title="Support"/>
        </section>
    );
}

export default Support;