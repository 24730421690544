import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';
import './AiButton.scss';

const AiButton = ({onClick}) => {
    return(
        <button className="ai-button" onClick={onClick}>
            <FontAwesomeIcon className="ai-button__icon" icon={faWandMagicSparkles}/>
        </button>
    );
}

export default AiButton;