import React from "react";
import "./DashboardHeader.scss";
import IconButton from "../IconButton/IconButton";
import { faBell, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import Button from "../Button/Button";
import { useNavigate } from "react-router";

function DashboardHeader({ layout, title, username}) {
    const navigate = useNavigate();
    const handleReturn = (e) => {
        e.preventDefault(); 
        navigate(-1);
    }

    switch (layout) {
        case "main":
            return (
                <div className="dashboard-header"> 
                    <div className="dashboard-header__left-panel">
                        <div className="dashboard-header__text-container">
                            <h3>Welcome {username}</h3> 
                            <p className="dashboard-header__text-container--text">Get ready to craft your resume</p>
                        </div>
        
                        <IconButton 
                            icon={faBell} 
                            className="dashboard-header__link-button--mobile" 
                            isLink={false}
                            buttonSize="xlarge"
                            color="dark"
                        />
                    </div>
        
                    <div className="dashboard-header__right-panel">
                        <IconButton 
                            icon={faBell} 
                            className="dashboard-header__link-button" 
                            isLink={false}
                            buttonSize="xlarge"
                            color="dark"
                        />
        
                        <Button 
                            isLink= {true}
                            text= "+ Create resume"
                            buttonSize= "small"
                            to="/template"
                        />
                    </div>
                </div>
            );

        case "subhead":
            return (
                <div className="dashboard-sub-header">
                    <h2 className="dashboard-sub-header__text">{title}</h2>
                </div>
            );

        default:
            return (
                <div className="dashboard-header--simplified">
                    <div className="dashboard-header__title-wrapper">
                        <IconButton
                            icon={faArrowLeft} 
                            className="dashboard-header__link-button--arrow"
                            isLink={false}
                            buttonSize="xlarge"
                            color="green"
                            onClick={handleReturn}
                        />
                        <h3>{title}</h3>
                    </div>
        
                    <IconButton 
                        icon={faBell} 
                        className="dashboard-header__link-button--simplified" 
                        isLink={false}
                        buttonSize="xlarge"
                        color="dark"
                    />
                </div>
            );
    }
}

export default DashboardHeader;