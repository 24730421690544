import React from "react";
import "./IconButton.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

function IconButton({isLink, icon, className, onClick, to, buttonSize, color}){
    return isLink?(
        <Link to={to} className={`icon-button ${className}`}>
            <FontAwesomeIcon icon={icon} className={`icon-button--icon icon-button--${buttonSize} icon-button--${color}`}/>
        </Link>
    ):(
        <button className={`icon-button ${className}`} onClick={onClick}>
            <FontAwesomeIcon icon={icon} className={`icon-button--icon icon-button--${buttonSize} icon-button--${color}`}/>
        </button>
    )
};

export default IconButton;