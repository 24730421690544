import React from "react";
import { useState } from "react";
import './AddTemplateOne.scss';
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import { faFile, faAddressCard, faBriefcase, faToolbox, faNewspaper } from '@fortawesome/pro-regular-svg-icons';
import InputField from "../../components/InputField/InputField";
import FormAccordionLabel from "../../components/FormAccordionLabel/FormAccordionLabel";
import Button from "../../components/Button/Button";
import Textarea from "../../components/Textarea/Textarea";
import CvTemplate from "../../components/CvTemplate/CvTemplate";
import IFrame from "../../components/IFrame/IFrame";
import html2pdf from 'html2pdf.js';
import { useNavigate } from "react-router";
import axios from "axios";

const AddTemplateOne = () =>{
    const apiUrl = process.env.REACT_APP_REDYY_API_URL;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [openIndex, setOpenIndex] = useState(null);
    const [showIframe, setShowIframe] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [formData, setFormData] = useState({
        fileName: '',
        name: '',
        jobTitle: '',
        phoneNumber: '',
        email: '',
        education: '',
        aboutMe: '',
        experiences: [],
        skills: [],
        certifications: []
    });

    const navigate = useNavigate();
    const handleReturn = (e) =>{
        e.preventDefault(); 
        navigate(-1);
    }

    const accordionToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => {
            const updatedFormData = { ...prev, [name]: value };
            return updatedFormData;
        });
    };

    const handlePhoneInputChange = (e) => {
        const { name, value } = e.target;
        let input = value.replace(/\D/g, ''); 
    
        if (input.length > 6) {
            input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`;
        } else if (input.length > 3) {
            input = `${input.slice(0, 3)}-${input.slice(3)}`;
        }
    
        setFormData(prev => {
            const updatedFormData = { ...prev, [name]: input }; 
            return updatedFormData;
        });
    
        setPhoneNumber(input);
    };
    
    const handleAddExperience = (e) => {
        e.preventDefault();
        setFormData(prev => ({
            ...prev,
            experiences: [...prev.experiences, { company: '', timeline: '', title: '', description: '' }]
        }));
    };

    const handleExperienceChange = (index, field, value) => {
        const updatedExperiences = formData.experiences.map((exp, i) => (
            i === index ? { ...exp, [field]: value } : exp
        ));
        setFormData(prev => ({ ...prev, experiences: updatedExperiences }));
    };

    const handleAddSkill = (e) => {
        e.preventDefault();
        setFormData(prev => ({
            ...prev,
            skills: [...prev.skills, '', '', '', '']
        }));
    };

    const handleSkillChange = (index, value) => {
        const updatedSkills = formData.skills.map((skill, i) => (
            i === index ? value : skill
        ));
        setFormData(prev => ({ ...prev, skills: updatedSkills }));
    };

    const handleAddCertification = (e) => {
        e.preventDefault();
        setFormData(prev => ({
            ...prev,
            certifications: [...prev.certifications, { name: '', timeline: '' }]
        }));
    };

    const handleCertificationChange = (index, field, value) => {
        const updatedCertifications = formData.certifications.map((cert, i) => (
            i === index ? { ...cert, [field]: value } : cert
        ));
        setFormData(prev => ({ ...prev, certifications: updatedCertifications }));
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
    
            const response = await axios.post(`${apiUrl}/api/resumes`, {
                file_name: formData.file_name || 'Untitled_CV',  
                name: formData.name || '',
                job_title: formData.jobTitle || '',
                phone_number: formData.phoneNumber || '',
                email: formData.email || '',
                education: formData.education || '',
                about_me: formData.aboutMe || '',
                experiences: formData.experiences?.length ? formData.experiences : [],
                skills: formData.skills?.length ? formData.skills : [],
                certifications: formData.certifications?.length ? formData.certifications : []
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            setIsSaved(true);
            setTimeout(() => {
                setIsSaved(false);
                navigate(-1); 
            }, 1000);
    
        } catch (error) {
            if (error.response?.status === 401) {  
                localStorage.removeItem('token');  
                navigate('/login');  
            }
        }
    };

    const handleDownload = () => {
        setShowIframe(false);   
    
        const pdfStyle = {
            margin: 0,   
            filename: `${formData.file_name}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 3,   
                letterRendering: true,
                useCORS: true   
            },
            jsPDF: { 
                unit: 'px',  
                format: [816, 1056],   
                orientation: 'portrait'  
            }
        };
    
        // took quite some time to figure out iFrame wont work with html2pdf
        setTimeout(() => {
            const element = document.getElementById('cv-content');
            if (element) {
                html2pdf().set(pdfStyle).from(element).save();
            }
            setShowIframe(true);  
        }, 100);  
    };

    return(
        <section className="add-cv">
            <SideNavbar />
            <main className="add-cv__content-container">
                <DashboardHeader isMain={false} title="Create resume"/>

                <div className="add-cv__list-container">
                    <div className="add-cv__list-container-left-panel">
                        <DashboardHeader layout="subhead" title="Resume builder"/>

                        <form className="add-cv__form-container">
                            <div className="add-cv__form-container-wrapper">
                                <div className="add-cv__list-container-form">
                                    <FormAccordionLabel icon={faFile} title="Resume version"  onClick={()=>accordionToggle(0)} className={`${openIndex === 0 ? 'add-cv__list-container-form--accordion-rotate' : ''}`}/>
                                    {openIndex === 0 &&
                                        <div className="add-cv__list-container-form--inner">
                                            <InputField 
                                                label="File name"
                                                type="text"
                                                name="file_name"
                                                placeholder=""
                                                isRequire={false}
                                                onChange={handleInputChange}  
                                                value={formData.file_name || ''} 
                                            />
                                        </div>
                                    }
                                </div>

                                <div className="add-cv__list-container-form">
                                    <FormAccordionLabel icon={faAddressCard} title="About me" onClick={()=>accordionToggle(1)} className={`${openIndex === 1 ? 'add-cv__list-container-form--accordion-rotate' : ''}`}/>
                                    {openIndex === 1 &&
                                        <div className="add-cv__list-container-form--inner">
                                            <div className="add-cv__list-container-form--side-by-side">
                                                <InputField 
                                                    label="Name"
                                                    type="text"
                                                    name="name"
                                                    placeholder="First name & last name"
                                                    isRequire={false}
                                                    onChange={handleInputChange}
                                                    value={formData.name}
                                                />
                                                <InputField 
                                                    label="Job title"
                                                    type="text"
                                                    name="job_title"
                                                    placeholder=""
                                                    isRequire={false}
                                                    onChange={handleInputChange}
                                                    value={formData.job_title || ''}
                                                />
                                            </div>
                                            <div className="add-cv__list-container-form--side-by-side">
                                                <InputField 
                                                    label="Phone number"
                                                    type="text"
                                                    name="phone_number"
                                                    placeholder=""
                                                    isRequire={false}
                                                    onChange={handlePhoneInputChange}
                                                    value={formData.phone_number || ''}
                                                />
                                                <InputField 
                                                    label="Email"
                                                    type="text"
                                                    name="email"
                                                    placeholder=""
                                                    isRequire={false}
                                                    onChange={handleInputChange}
                                                    value={formData.email || ''}
                                                />
                                            </div>
                                                <InputField 
                                                    label="Education"
                                                    type="text"
                                                    name="education"
                                                    placeholder=""
                                                    isRequire={false}
                                                    onChange={handleInputChange}
                                                    value={formData.education || ''}
                                                />
                                                <Textarea 
                                                    label="Intro"
                                                    type="text"
                                                    name="about_me"
                                                    placeholder="e.g., About yourself"
                                                    isAiButton={true}
                                                    onChange={handleInputChange}
                                                    value={formData.about_me || ''}
                                                />
                                        </div>
                                    }
                                </div>

                                <div className="add-cv__list-container-form">
                                    <FormAccordionLabel icon={faBriefcase} title="Experience" onClick={()=>accordionToggle(2)} className={`${openIndex === 2 ? 'add-cv__list-container-form--accordion-rotate' : ''}`}/>
                                    {openIndex === 2 && (
                                        <div className="add-cv__list-container-form--experience-wrapper">
                                        {formData.experiences.map((exp, index) => (
                                            <div key={index} className="add-cv__list-container-form--inner">
                                                <div className="add-cv__list-container-form--side-by-side">
                                                    <InputField 
                                                        label="Timeline"
                                                        type="text"
                                                        placeholder="e.g., 2019 - present"
                                                        isRequire={false}
                                                        value={exp.timeline}
                                                        onChange={(e) => handleExperienceChange(index, 'timeline', e.target.value)}  
                                                    />
                                                    <InputField 
                                                        label="Company"
                                                        type="text"
                                                        placeholder=""
                                                        isRequire={false}
                                                        value={exp.company}
                                                        onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
                                                    />
                                                </div>
                                                <InputField 
                                                    label="Job title"
                                                    type="text"
                                                    name="jobTitle"
                                                    placeholder=""
                                                    isRequire={false}
                                                    value={exp.title}
                                                    onChange={(e) => handleExperienceChange(index, 'title', e.target.value)}
                                                />
                                                <Textarea 
                                                    label="Intro"
                                                    type="text"
                                                    name="intro"
                                                    placeholder="e.g., About experience"
                                                    isAiButton={true}
                                                    value={exp.description}
                                                    onChange={(e) => handleExperienceChange(index, 'description', e.target.value)}
                                                />
                                            </div>
                                        ))}
                                            <Button type="secondary" text="+ Add Experience" onClick={handleAddExperience} buttonSize="small"/>
                                        </div>
                                    )}
                                </div>

                                <div className="add-cv__list-container-form">
                                    <FormAccordionLabel icon={faToolbox} title="Skill" onClick={() => accordionToggle(3)} className={`${openIndex === 3 ? 'add-cv__list-container-form--accordion-rotate' : ''}`}/>
                                    {openIndex === 3 && (
                                        <div className="add-cv__list-container-form--inner">
                                            {formData.skills.length > 0 && (
                                                formData.skills.reduce((resultArray, skill, index) => {
                                                    const gropIndex = Math.floor(index / 4);

                                                    if (!resultArray[gropIndex]) {
                                                        resultArray[gropIndex] = [];
                                                    }

                                                    resultArray[gropIndex].push(skill);
                                                    return resultArray;
                                                }, []).map((chunk, gropIndex) => (
                                                    <div key={gropIndex} className="add-cv__list-container-form--side-by-side">
                                                        {chunk.map((skill, skillIndex) => (
                                                            <InputField
                                                                key={skillIndex}
                                                                label="Skill"
                                                                type="text"
                                                                placeholder=""
                                                                value={skill}
                                                                isRequire={false}
                                                                onChange={(e) => handleSkillChange(skillIndex + gropIndex * 4, e.target.value)} // Adjust index calculation
                                                            />
                                                        ))}
                                                    </div>
                                                ))
                                            )}
                                            <Button type="secondary" text="+  Add Skill" onClick={handleAddSkill} buttonSize="small" />
                                        </div>
                                    )}
                                </div>

                                <div className="add-cv__list-container-form">
                                    <FormAccordionLabel icon={faNewspaper} title="Certification" onClick={()=>accordionToggle(4)} className={`${openIndex === 4 ? 'add-cv__list-container-form--accordion-rotate' : ''}`}/>
                                    {openIndex === 4 &&
                                        <div className="add-cv__list-container-form--inner">
                                            {formData.certifications.map((cert, index) => (
                                            <div key={index} className="add-cv__list-container-form--side-by-side">
                                                <InputField 
                                                    label="Certification"
                                                    type="text"
                                                    placeholder=""
                                                    isRequire={false}
                                                    value={cert.name}
                                                    onChange={(e) => handleCertificationChange(index, 'name', e.target.value)}
                                                />
                                                <InputField 
                                                    label="Certification details"
                                                    type="text"
                                                    placeholder=""
                                                    isRequire={false}
                                                    value={cert.about}
                                                    onChange={(e) => handleCertificationChange(index, 'about', e.target.value)}
                                                />
                                            </div>
                                            ))}
                                            <Button type="secondary" text="+  Add Certification" onClick={handleAddCertification} buttonSize="small" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                        <div className="add-cv__sticky">
                            <Button 
                                type="ghost"
                                isLink={false}
                                text="Download"
                                buttonSize="xsmall"
                                onClick={handleDownload}
                            />
                            <div className="add-cv__sticky--side-by-side">
                                {isSaved && <p className="edit-cv__save-text">🤩 Saved</p>}
                                <Button 
                                    type="secondary"
                                    isLink={false}
                                    text="Cancel"
                                    buttonSize="xsmall"
                                    onClick={handleReturn}
                                />

                                <Button 
                                    isLink={false}
                                    text="Save"
                                    buttonSize="xsmall"
                                    onClick={handleSave} 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="add-cv__list-container-right-panel">
                        <DashboardHeader 
                            layout="subhead" 
                            border="left" 
                            title="Preview"
                        />
                        <div className="add-cv__list-container-right-panel-preview">
                        {showIframe?(
                            <IFrame width="100%" height="792px" scale="0.7">
                                <CvTemplate
                                    name={formData.name}
                                    jobTitle={formData.job_title}
                                    phoneNumber={formData.phone_number}
                                    email={formData.email}
                                    education={formData.education}
                                    aboutMe={formData.about_me}
                                    experiences={formData.experiences}
                                    skills={formData.skills}
                                    certifications={formData.certifications}
                                    className="edit-cv__cv-preview"
                                />
                            </IFrame>
                        ):(
                            <CvTemplate
                                name={formData.name}
                                jobTitle={formData.job_title}
                                phoneNumber={formData.phone_number}
                                email={formData.email}
                                education={formData.education}
                                aboutMe={formData.about_me}
                                experiences={formData.experiences}
                                skills={formData.skills}
                                certifications={formData.certifications}
                                className="edit-cv__cv-preview"
                                id="cv-content"
                            />
                        )}
                        </div>
                    </div>
                </div>
            </main>
        </section>
    );
}

export default AddTemplateOne;