import React from "react";
import './CardTile.scss'

const CardTile = ({heading, body, img}) =>{
    return(
        <div className="card-tile">
            <div className="card-tile__upper">
                <h3>{heading}</h3>
                <p className="card-tile__text">{body}</p>
            </div>

            <img className="card-tile__image" src={img} alt="Redyy dashboard"/>
        </div>
    );
};

export default CardTile;