import React, { useEffect, useState } from "react";
import './CompanyTable.scss';
import CompanyTableItem from "../CompanyTableItem/CompanyTableItem";
import RedyySimplifiedLogo from '../../assets/logo/redyy-logo-small.svg'

const fetchCompanies = async (page) => {
    const url = `https://www.themuse.com/api/public/companies?page=${page}`;
    const response = await fetch(url);
    const data = await response.json();
    return data.results || [];
};

const CompanyTable = () => {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchMultiplePages = async () => {
            const uniqueCompanies = new Set();  
            const allCompanies = [];

            for (let page = 1; page <= 5; page++) {  
                const pageCompanies = await fetchCompanies(page);
                pageCompanies.forEach(company => {
                    if (!uniqueCompanies.has(company.id)) {  
                        uniqueCompanies.add(company.id);
                        allCompanies.push(company);
                    }
                });

                if (allCompanies.length >= 5) {
                    break;  
                }
            }

            setCompanies(allCompanies.slice(0, 5));  
        };

        fetchMultiplePages();
    }, []);

    return (
        <section className="company-table">
            {companies.length > 0 ? (
                companies.map((company, index) => (
                    <CompanyTableItem 
                        key={index}
                        companyName={company.name}
                        logo={company.refs.logo_image}
                        to={company.refs.landing_page}
                    />
                ))
            ) : (
                <p className="loader">Loading companies...</p>
            )}
        </section>
    );
}

export default CompanyTable;