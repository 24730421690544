import React from "react";
import "./ComingSoon.scss";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import Footer from "../../components/Footer/Footer";
import Redyy3D from "../../assets/logo/3d-redyy-logo.png";


const ComingSoon = ({title}) =>{
    return(
        <section className="coming-soon">
            <SideNavbar />

            <main className="coming-soon__content-container">
                <DashboardHeader  title={title}/>

                <div className="coming-soon__inner-container">
                    <img className="coming-soon__inner-container--image" src={Redyy3D} alt="Redyy logo 3D"/>
                    <h1 className="coming-soon__inner-container--title">Stay tuned!</h1>
                </div>

                <Footer layout="simplified-grey" />
            </main>
        </section>
    );
};

export default ComingSoon;