import React from "react";
import './FormAccordionLabel.scss';
import Icon from "../../components/Icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const FormAccordionLabel = ({icon, title, onClick, className}) =>{
    return(
        <label className="form-accordion-label" onClick={onClick}>
            <div className="form-accordion-label__wrapper">
                <FontAwesomeIcon icon={icon} className="form-accordion-label__icon"/>
                <h3 className="form-accordion-label__title">{title}</h3>
            </div>

            <Icon IconSize="default" icon={faCaretDown} color="dark" className={`form-accordion-label__icon--animation ${className}`}/>
        </label>
    );
}

export default FormAccordionLabel;