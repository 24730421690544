import React from "react";
import "./Notification.scss";
import Icon from "../Icon/Icon";
import { faCircleXmark, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Notification = ({icon, text, layout}) =>{
    switch(layout){
        case "error":
            return(
                <div className="notification notification--error">
                    <FontAwesomeIcon icon={faCircleXmark} className="notification__icon notification__icon--error"/>
                    <h3 className="notification__text notification__text--error">{text}</h3>
                </div>
            );
        
        case "info":
            return(
                <div className="notification notification--info">
                    <FontAwesomeIcon icon={faCircleInfo} className="notification__icon notification__icon--info"/>
                    <h3 className="notification__text notification__text--info">{text}</h3>
                </div>
            );
        
        default:
            return(
                <div className="notification notification--default">
                    <FontAwesomeIcon icon={icon} className="notification__icon notification__icon--default"/>
                    <h3 className="notification__text notification__text--default">{text}</h3>
                </div>
            );
    }
};

export default Notification;