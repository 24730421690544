import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // useNavigate for redirection
import "./Signup.scss";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Button from "../../components/Button/Button";
import RedyyLogo from "../../assets/logo/redyy-logo-small.svg";
import Gif from "../../assets/gif/3d-isometric.gif";
import InputField from "../../components/InputField/InputField";
import axios from "axios";
import Notification from "../../components/Notification/Notification";

function Signup() {
    const apiUrl = process.env.REACT_APP_REDYY_API_URL;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName || !email || !password) {
            setError("All fields are required.");
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/auth/signup`, {
                firstName,
                lastName,
                email,
                password,
            });

            if (response.status === 200) {
                setError("");   
                navigate("/login");   
            }
        } catch (error) {
            setError("Signup failed. Please check your info.");
        }
    };

    return (
        <section className="signup">
            <Navbar layout="simplified"/>

            <main className="signup__main-container">
                <div className="signup__left-panel">
                    <div className="signup__text-wrapper">
                        <img className="signup__redyy-logo" src={RedyyLogo} alt="Redyy logo"/>
                        <span className="signup__text-wrapper--tagline tagline">Build your resume today!</span>
                        <h2 className="signup__text-wrapper--title">Welcome to Redyy</h2>
                    </div>

                    {error && <Notification text={error} layout="error" />}

                    <form className="signup__field-wrapper" onSubmit={handleSignup}>
                        <div className="signup__field-wrapper--form">
                            <div className="signup__field-wrapper-upper">
                                <InputField
                                    label="First name"
                                    type="text"
                                    placeholder=""
                                    value={firstName} 
                                    onChange={(e) => setFirstName(e.target.value)}  
                                    isRequire={true}
                                />
                                
                                <InputField
                                    label="Last name"
                                    type="text"
                                    placeholder=""
                                    value={lastName} 
                                    onChange={(e) => setLastName(e.target.value)}  
                                    isRequire={true}
                                />
                            </div>

                            <InputField
                                label="Email"
                                type="text"
                                placeholder=""
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}  
                                isRequire={true}
                            />
                            
                            <InputField
                                label="Password"
                                type="password" 
                                placeholder=""
                                value={password}  
                                onChange={(e) => setPassword(e.target.value)} 
                                isRequire={true} 
                            />
                        </div>

                        <Button
                            text="Signup"
                            isLink={false}
                            buttonSize="default"
                            buttonWidth="full-width"
                            onClick={handleSignup}  
                        />
                    </form>

                    <p className="signup__signup-text">Already using Redyy?&#160;
                        <span>
                            <Link to="/login" className="signup__signup-text--link">Sign in to an existing account</Link>
                        </span>
                    </p>
                </div>

                <div className="signup__right-panel">
                    <img className="signup__right-panel--gif" src={Gif} alt="3D isometric animation"/>
                </div>
            </main>

            <Footer layout="simplified-green"/>
        </section>
    );
}

export default Signup;