import React from "react";
import "./Logo.scss";
import RedyyLogo from "../../assets/logo/redyy-logo.svg";
import { Link } from "react-router-dom";

function Logo({isDashboard}){
    return isDashboard?(
        <Link to='/dashboard' className="logo">
            <img className="logo__svg" src={RedyyLogo} alt="Redyy logo"/>
        </Link>
    ):(
        <Link to='/' className="logo">
            <img className="logo__svg" src={RedyyLogo} alt="Redyy logo"/>
        </Link>
    )
};

export default Logo;
