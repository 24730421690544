import React from "react";
import './TableTitle.scss';
import Icon from "../Icon/Icon";

const TableTitle = ({icon, title}) => {
    return(
        <div className="table-title">
            <Icon icon={icon} IconSize="xlarge" color="green"/>
            <p className="table-title--text">{title}</p>
        </div>
    );
}

export default TableTitle;